$body-background: #fff;
$body-color: #444;

body {
  background: $body-background;
  color: $body-color;
  font-family: "Raleway", sans-serif;
}

h1, h2 {
  font-weight: 200;
}

h1, nav, footer {
  text-align: center;
}

main {
  margin: 4rem auto;
  max-width: 60rem;
}

a {
  color: #000;
  text-decoration: none;
}
header {
  display: flex;
  text-align: center;
  flex-direction: column;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
}

nav > ul {
  display: flex;
  justify-content: center;
  list-style: none;
  text-align: center;

  li {
    padding: 1em;
  }
}

.post-link {
  padding: 0.5em 0;
}

.social {
  ul {
    display: flex;
    justify-content: center;
    list-style: none;
    margin-top: 0;
    text-align: center;
  }
}

.icon {
  width: 1.5rem;
  margin: 0 0.8rem;
}

.avatar {
  width: 100%;
}

.post main {
  a {
    text-decoration: underline;
  }

  img {
    width: 100%;
  }

  .center {
    display: flex;
    justify-content: center;
  }
}
